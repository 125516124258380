import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/js/bootstrap.min.js'
import "./Styles/elastiq.css"
import MainContent from './MainContent';
import VerifyFaces from './components/verify_faces'


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/verify_faces" element={<VerifyFaces />} />
      </Routes>
    </Router>
  );
}

export default App;