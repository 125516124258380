import React, { useState } from "react";
import CroppedImage from "./CroppedImage";
import axios from "axios";
import config from "../config.json";
import Spinner from "./Spinner";
import "bootstrap/dist/css/bootstrap.min.css";

function ImageResultHeader({
  setResultData,
  setLoading,
  selectedFile,
  imageSrc,
  facialRecogData,
  facialLoading,
}) {
  const serviceUrl = config.serviceUrl;
  const [showAll, setShowAll] = useState(false);

  const visibleData = showAll ? facialRecogData : facialRecogData.slice(0, 10);

  const handleSeeAllClick = () => {
    setShowAll(true);
  };

  const handleShowLessClick = () => {
    setShowAll(false);
  };


  async function handleClick() {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    const response = await axios.post(
      serviceUrl + "/search-image",
      formData,
      config
    );
    if (response.data) {      
      setLoading(false);
      setResultData(response.data);
    }
  }

  return (
    <div className="image-result-header bg-light p-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="text-container">
              <p className="font-weight-bold">Showing results similar to:</p>
            </div>
            <div className="image-container" onClick={handleClick}>
              <img
                src={imageSrc}
                alt="User Input"
                className="img-fluid border mb-3"
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="text-container">
              {!facialLoading && facialRecogData.length > 0 ? (
                <p className="font-weight-bold">Faces Found:</p>
              ) : (
                <p className="font-weight-bold">Detecting Faces...</p>
              )}
            </div>
            {!facialLoading && facialRecogData.length > 0 ? (
              <>
                <div className="d-flex flex-wrap justify-content-start">
                  {visibleData.map((data, index) => (
                    <div
                      key={index}
                      className="col-6 col-sm-4 col-lg-2 mb-3 ms-4 d-flex justify-content-start"
                    >
                      <CroppedImage
                        setResultData={setResultData}
                        setLoading={setLoading}
                        imageUrl={imageSrc}
                        personID={data[0]}
                        personName={data[1]}
                        faceEmbedding={data[8]}
                        faceCount={parseInt(data[7])}
                        cropX={parseInt(data[3])}
                        cropY={parseInt(data[4])}
                        cropWidth={parseInt(data[5])}
                        cropHeight={parseInt(data[6])}
                        className="img-fluid"
                      />
                    </div>
                  ))}
                </div>
                {!showAll && facialRecogData.length > 10 && (
                  <div className="text-right mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={handleSeeAllClick}
                    >
                      Show All
                    </button>
                  </div>
                )}
                {showAll && (
                  <div className="text-right mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={handleShowLessClick}
                    >
                      Show Less
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div
                id="spinner"
                className="d-flex justify-content-left align-items-left my-2"
              >
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageResultHeader;
