import React, { useState } from "react";
import axios from "axios";
import config from "../../config.json";

function GridCardPerson({
  props,
  img,
  idx,
  sizeFactor,
  setShowModal,
  setModalChildren,
}) {
  const [personName, setPersonName] = useState(img[2]);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState("");
  const serviceUrl = config.serviceUrl;

  const handleEditName = async () => {
    setIsEditing(true);
  };

  const handleSaveName = async (id, newName) => {
    // console.log("handleSaveName id: ", id);
    const requestBody = { id: id, name: newName };
    let response = await axios.post(
      serviceUrl + "/person/rename",
      requestBody,
      config
    );
    setPersonName(newName);
    setIsEditing(false);
    // console.log("Name updated successfully");
  };

  const handleSearch = async (id) => {
    props.setScreen("search");
    props.setInputText("[" + personName + "]");
    props.setInputPersons([id]);
  };

  return (
    <div
      key={idx}
      className={`col-${Math.floor(12 / sizeFactor)} pe-1 pb-1 ps-0 pt-0`}
    >
      <div className="img-wrapper position-relative">
        <img className="zoom" src={img[1]} alt={personName} />
        <p className="position-absolute top-0 start-50 translate-middle-x mt-2 text-center fw-bold fs-4 text-white">
  {personName}
</p>

        <div className="content fade p-2">
          <div className="card bg-transparent w-100 h-100">
            <div className="card-body overflow-auto">
              {isEditing ? (
                <input
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  placeholder={personName}
                />
              ) : null}
            </div>
            <div className="card-footer bg-transparent border-top-0 d-flex justify-content-end align-items-center">
              {isEditing ? (
                <>
                  <button
                    type="button"
                    className="btn border bg-white me-2"
                    onClick={() => handleSaveName(img[3], newName)}
                  >
                    Save
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn border bg-white me-2"
                  onClick={handleEditName}
                >
                  Edit Name
                </button>
              )}
              <button
                type="button"
                className="btn border bg-white"
                onClick={() => handleSearch(img[3])}
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridCardPerson;
