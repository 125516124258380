import React from 'react';
import { useState } from 'react';
import Spinner from '../Spinner';
import GridCardPerson from './GridCardPerson';
import ContentModal from '../ContentModal';

function GridLayoutPerson(props) {

    const [sizeFactor, setSizeFactor] = useState(4);
    const sizes = [6, 4, 3, 2];
    const handleRangeChange = (event) => {
        setSizeFactor(parseInt(sizes[event.target.value]));
    };

    const [showModal, setShowModal] = useState(false);
    const [modalChildren, setModalChildren] = useState([]);

    const renderRows = () => {
        let rows = [];
        for (let i = 0; i < props.data.length; i += sizeFactor) {
            rows.push(
                <div key={i} className="row">
                    {props.data.slice(i, i + sizeFactor).map((img, idx) => {
                        return (
                            <GridCardPerson
                                props={props}
                                img={img}
                                idx={idx}
                                sizeFactor={sizeFactor}
                                key={idx}
                            />
                        )
                    })}
                </div>
            );
        }
        return rows;
    };


    return (

        <div onScroll={props.handleScroll}>

            {props.loading ? <Spinner /> :
                <>
                    <div className="row py-2 justify-content-end">
                        <div className="col-12 col-md-2 text-end">
                            <label htmlFor="customRange3" className="form-label">Display Size : </label>
                        </div>
                        <div className="col-12 col-md-2">
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="3"
                                step="1"
                                id="customRange3"
                                value={sizes.indexOf(sizeFactor)}
                                onChange={handleRangeChange}

                            />
                        </div>
                    </div>
                    {props.data.length? renderRows(): 
                    <div className="text-center">
                        No result found!
                    </div>
                    }
                    
                    {
                        <ContentModal
                            show={showModal}
                            setShowModal={setShowModal}
                            children={modalChildren}
                        />
                    }
                </>
            }
        </div>

    );
};
export default GridLayoutPerson;
