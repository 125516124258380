import React from "react";
import { useRef } from "react";
import "../Styles/search.css";
import "bootstrap/dist/css/bootstrap.min.css";

function SearchBox(props) {
  const inputTextRef = useRef(null);


  const handleInputChange = async (event, value) => {
    const inputValue = value || event.target.value;
    props.setInputText(inputValue);
  };
  const handleFileChange = (event) => {
    props.setSelectedFile(event.target.files[0]);
    props.setShowImageResult(false);
  };

  const handleClear = () => {
    props.setScreen("search");
    props.setResultData([]);
    props.setInputText("");
    if (props.searchType === "image") {
      props.setSelectedFile(null);
      const fileInput = document.getElementById("inputGroupFile01");
      if (fileInput) {
        fileInput.value = "";
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      props.search();
    }
  };

  return (
    <div className="search-box-container">
      <div className="input-group input-group-lg">
        {props.searchType === "image" && (
          <input
            type="file"
            className="form-control form-control-lg"
            id="inputGroupFile01"
            onChange={handleFileChange}
          />
        )}
        {props.searchType === "text" && (
          <input
            type="text"
            className="form-control"
            placeholder="Search ..."
            value={props.inputText}
            onChange={handleInputChange}
            ref={inputTextRef}
            onKeyDown={handleKeyPress}
          />
        )}
        <button className="btn btn-light border" onClick={(event) => {
          event.preventDefault();
          props.search()
        }}>
          <i className="bi bi-search"></i>
        </button>
        {props.searchType === "text" && (
          <button
            className="btn btn-light border"
            type="button"
            onClick={() => {
              props.setSearchType("image");
            }}
          >
            <i className="bi bi-camera"></i>
          </button>
        )}

        {props.searchType === "image" && (
          <button
            className="btn btn-light border"
            onClick={() => {
              props.setSearchType("text");
            }}
          >
            <i className="bi bi-cursor-text"></i>
          </button>
        )}
        <button className="btn btn-light border" onClick={handleClear}>
          <i className="bi bi-x-lg"></i>
        </button>
      </div>
    </div>
  );
}

export default SearchBox;
